
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ITableResponse } from "@/core/entity/ITableResponse";
import { IProduct } from "@/core/entity/IProduct";

import router from "@/router";

export default defineComponent({
  name: "Products",
  components: { KtDatatable, Entities},
  setup() {
    const filterObject = ref({})
    const store = useStore()

    onMounted(() => {
      if (!store.getters.isAdmin) {
        router.push({name: "403"});
      }
      setCurrentPageBreadcrumbsWithParams('Product',
        [
          {link: false, router: '', text: 'List'}
        ]
      )
    })
    const tableHeader = ref([
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const productPage = computed<ITableResponse<IProduct>>(() => store.state.ProductModule.productPage)
    return {
      tableHeader,
      ...LoadFilterObjects(Actions.LOAD_PRODUCTS, filterObject.value, ['company']),
      productPage,
    }
  },
  methods: {

  }
})
